import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Box,
  Avatar,
} from "@mui/material";
import Web3 from "web3";
import TRLKPrivatePresaleABI from "./smartcontract.json";
import Logo from "./LogoTransparent.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Slideshow from "./Slideshow";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const App = () => {
  const [web3, setWeb3] = useState(null);
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [busdToken, setBusdToken] = useState(null);
  const [contract, setContract] = useState(null);
  const [depositAmount, setDepositAmount] = useState(0);
  const [referralCode, setReferralCode] = useState("");
  const [preferPhoenixBlockchain, setPreferPhoenixBlockchain] = useState(false);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [payments, setPayments] = useState([]);
  // Get the location object from react-router-dom
  const location = useLocation();
  // Store target deposit open date 
  const depositOpenDate = new Date('August 2, 2023 00:00:00 GMT');

  // Countdown timer state
  const [timeRemaining, setTimeRemaining] = useState(null);
  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          setWeb3(web3);
          const accounts = await web3.eth.getAccounts();
          setUserWalletAddress(accounts[0]);
          const networkId = 56; // BSC network id
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${networkId.toString(16)}` }],
          });
        } catch (error) {
          console.error("Error while connecting to Metamask:", error);
        }
      } else {
        console.error("Please install MetaMask!");
      }
    };

    initWeb3();
  }, []);

  useEffect(() => {
    // Check if there's a referral code in the URL parameter
    const referralCodeParam = new URLSearchParams(location.search).get("referralCode");
    if (referralCodeParam) {
      setReferralCode(referralCodeParam);
    }
  }, [location]);

  useEffect(() => {
    if (web3) {

      // Replace 'YOUR_BUSDTOKEN_ADDRESS' with the actual address of the BUSD token contract
      const busdTokenAddress = TRLKPrivatePresaleABI.busdAddress;
      const busdToken = new web3.eth.Contract(TRLKPrivatePresaleABI.busdABI, busdTokenAddress);
      setBusdToken(busdToken);

      // Replace 'YOUR_CONTRACT_ADDRESS' with the actual address of your deployed contract
      const contractAddress = TRLKPrivatePresaleABI.address;
      const contract = new web3.eth.Contract(TRLKPrivatePresaleABI.abi, contractAddress);
      setContract(contract);

      
      // Fetch initial data
      getTotalDeposits(contract);
      getAllPayments(contract);
    }
  }, [web3]);

  useEffect(() => {

    const interval = setInterval(() => {

      const currentDate = new Date();

      // Calculate time remaining
      const diff = depositOpenDate - currentDate;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);

    }, 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);

  }, []);


  // Disable deposit button until depositOpenDate
  const depositDisabled = new Date() < depositOpenDate;


  const getTotalDeposits = async (contract) => {
    if (contract) {
      try {
        const totalDeposits = await contract.methods.getTotalDeposits().call();
        setTotalDeposits(Web3.utils.fromWei(totalDeposits.toString(), "ether") );
      } catch (error) {
        console.error("Error while fetching total deposits:", error);
      }
    }
  };

  const getAllPayments = async (contract) => {
    if (contract) {
      try {
        const paymentData = await contract.methods.getAllPayments().call();
        const walletAddresses = paymentData[0];
        const paymentAmounts = paymentData[1];
        const referralCodes = paymentData[2];
        const preferPhoenixBlockchainStatus = paymentData[3];
        const payments = [];
        for (let i = 0; i < walletAddresses.length; i++) {
          payments.push({
            walletAddress: walletAddresses[i],
            amount: paymentAmounts[i],
            referralCode: referralCodes[i],
            preferPhoenixBlockchain: preferPhoenixBlockchainStatus[i],
          });
        }
        console.log(payments);

        setPayments(payments);
      } catch (error) {
        console.error("Error while fetching payments:", error);
      }
    }
  };

  const handleDeposit = async () => {
    try {
      if (depositAmount < 30) {
        toast.error("Minimum deposit amount is 30 BUSD");
        return;
      }
      const networkId = 56; // BSC network id
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${networkId.toString(16)}` }],
      });
      const amountWithDecimals = Web3.utils.toWei(depositAmount.toString(), "ether");
      
      // Step 1: Check if the referral code is valid using the getWalletAddressByReferralCode function
      const referralAddress = await contract.methods.getWalletAddressByReferralCode(referralCode).call();
      if (referralAddress === "0x0000000000000000000000000000000000000000") {
        throw new Error("Invalid referral code. Please enter a valid referral code. or get one from our telegram group.");
      }
      // Step 2: Approve the contract to spend BUSD tokens on behalf of the user
      await busdToken.methods.approve(contract.options.address, amountWithDecimals).send({ from: userWalletAddress });

      // Step 3: Call the deposit function on the contract
      await contract.methods.deposit(amountWithDecimals, referralCode, preferPhoenixBlockchain).send({ from: userWalletAddress });

      // Step 3: Refresh the total deposits and payments
      getTotalDeposits(contract);
      getAllPayments(contract);
    } catch (error) {
      console.error("Error during deposit:", error);
      toast.error("Error during deposit:"+ error);
    }
  };

  const handleRefund = async () => {
    try {
      await contract.methods.refund().send({ from: userWalletAddress });
      // Refresh the total deposits and payments
      getTotalDeposits(contract);
      getAllPayments(contract);
    } catch (error) {
      console.error("Error during refund:", error);
    }
  };

  // Create a dark theme for Material-UI
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const depositAmountValid = depositAmount >= 30;

const depositButtonDisabled = depositDisabled ||(!depositDisabled && !depositAmountValid);
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: "#0a192f", // Set the background color to #0a192f
          minHeight: "100vh", // Ensure the content takes up the full height of the viewport
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Avatar
            src={Logo} // Replace with the path to your circular logo image
            alt="TrustLinker Logo"
            sx={{ width: 120, height: 120, bgcolor: "#fff" }}
          />
          <Typography variant="h4" component="h1" mt={2}>
            TrustLinker
          </Typography>
        </Box>
        <br />
        {web3 == undefined && (
        <Typography variant="h5" color="error">
          Please use a browser with MetaMask/TrustWallet installed to interact with this dApp.
        </Typography>
      )}
        <Container maxWidth="md">
          <Slideshow />
          <Box mt={4}>
            <Typography variant="h5" component="h2" mb={2} color="#fff">
              Welcome to TrustLinker Private Presale
            </Typography>
            <Typography variant="body1" color="#fff" align="justify">
              Whether you're a skilled freelancer or an employer looking for reliable talent, TrustLinker is here to make your experience seamless and secure. Our platform is designed to foster trust and transparency, ensuring that you find trustworthy contracts and forge successful collaborations.
            </Typography>
            <Typography variant="body1" color="#fff" mt={2} align="justify">
              The TrustLinker Private Presale is an exclusive opportunity for early
              adopters and supporters to participate in the project's growth. By
              participating in the private presale, you can secure your allocation of
              TrustLinker tokens (TRLK) at a discounted rate before the public sale.
            </Typography>
            <Typography variant="body1" color="#fff" mt={2} align="justify">
              To participate in the private presale, simply enter the amount of BUSD you
              wish to deposit, along with the referral code from the partner you came from. You
              can also indicate whether you prefer the Phoenix Blockchain for your
              transactions (this will grant you 5% bonus). After the presale is over, you will receive TRLK tokens based on the
              amount deposited and the private presale rate.
            </Typography>
            <Typography variant="body1" color="#fff" mt={2} align="justify">
              Tokenomics:
            </Typography>

              <ul>
                <li>Total Supply: 10,000,000 $TRLK</li>
                <li>Private Sale (BSC): 1,500,000 $TRLK</li>
                <li>Private Sale (Phoenix): 500,000 $TRLK</li>
                <li>Public Sale (BSC): 1,750,000 $TRLK</li>
                <li>Public Sale (Phoenix): 250,000 $TRLK</li>
                <li>Liquidity (BSC): 3,000,000 $TRLK</li>
                <li>Liquidity (Phoenix): 1,000,000 $TRLK</li>
                <li>Team and Development: 1,500,000 $TRLK</li>
                <li>Marketing and Partnerships: 500,000 $TRLK</li>
              </ul>
            {/* Specify Current Presale Price */}
            <Typography variant="h4" color="#3879c1" mt={2} align="justify">
              Current Presale Price: <span style={{ color: "#ff9800", fontWeight: "bold" }}>$0.04</span> per $TRLK
            </Typography>
            <Typography variant="body1" color="#fff" mt={2} align="justify">
              If you have any questions or need assistance, feel free to reach out to our
              team via email at CEO@trustlinker.net or join our{" "}
              <a
                href="https://t.me/TrustLinker" // Replace with your Telegram group link
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#ff9800", textDecoration: "none" }}
              >
                Telegram community
              </a>{" "}
              for real-time support and updates.
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" component="h2" mb={2}>
              Your Wallet Address: {userWalletAddress}
            </Typography>
            <Typography variant="body1" mb={2}>
              Total Deposits: {totalDeposits} BUSD
            </Typography>
          </Box>
          {web3 == undefined && (
        <Typography variant="h5" color="error">
          Please use a browser with MetaMask/TrustWallet installed to interact with this dApp.
        </Typography>
      )}
          {web3 && (
          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" component="h2" mb={2}>
              Deposit
            </Typography>
            
      
            <TextField
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              label="Amount (BUSD)"
              variant="outlined"
              size="small"
              mb={1}
              inputProps={{
                min: 30, // set min value to 30
                step: 0.01 // use increments of 0.01
              }}
            />
            <TextField
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              label="Referral Code"
              variant="outlined"
              size="small"
              mb={1}
              required
            />
            <br></br>
            <label style={{ color: "#fff" }}>Which blockchain you want to get your tokens on?</label>
            <Select
              value={preferPhoenixBlockchain ? "phoenix" : "bsc"}
              onChange={(e) => setPreferPhoenixBlockchain(e.target.value === "phoenix")}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              size="small"
              mb={1}
            >
              <MenuItem value="bsc">Binance Smart Chain (BSC)</MenuItem>
              <MenuItem value="phoenix">Phoenix Blockchain (5% bonus $TRLK)</MenuItem>
            </Select>
            <br/>
            {
              depositDisabled && (
                <Typography>Deposit opens in: <span style={{ color: "#ff9800", fontWeight: "bold" }}>{timeRemaining}</span></Typography>
              )
            }
            <Button variant="contained" color="primary" onClick={handleDeposit} disabled={depositButtonDisabled} >
              Deposit
            </Button>
            
          </Box>
          )}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" component="h2" mb={2}>
              Payments
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Wallet Address</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Referral Code</TableCell>
                    <TableCell>Chosen blockchain</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment, index) => (
                    <TableRow key={index}>
                      <TableCell>{payment.walletAddress}</TableCell>
                      <TableCell>
                        {Web3.utils.fromWei(payment.amount.toString(), "ether")} BUSD
                      </TableCell>
                      <TableCell>{payment.referralCode}</TableCell>
                      <TableCell>
                        {payment.preferPhoenixBlockchain ? "Phoenix Blockchain" : "Binance Smart Chain (BSC)"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Button variant="contained" color="secondary" onClick={handleRefund} style={{ display: "none" }}>
              Refund
            </Button>
          </Box>
        </Container>
        <ToastContainer position="top-center" autoClose={10000} />
      </div>
    </ThemeProvider>
  );
};

export default App;
