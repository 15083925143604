import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slideshow = () => {
  const slides = [
    "/slideshows/slide1.jpg",
    "/slideshows/slide2.jpg",
    "/slideshows/slide4.jpg",
  ];

  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      transitionTime={500}
      stopOnHover={false}
    >
      {slides.map((slide, index) => (
        <div key={index}>
          <img src={slide} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;
